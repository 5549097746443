import * as React from "react"
import { LogoChartOverlay } from '../components/LogoChartOverlay';
import { AppStoreDownload } from '../components/AppStoreDownload';
import '../styles.scss';

const EmojiCard = ({
  style
  }) => (
  <div style={style}className="landing-container__emoji-card">
    <span className="landing-container__emoji-card-element">
      💎🤲 
    </span>
    <span className="landing-container__emoji-card-element">
      🚀
    </span>
    <span className="landing-container__emoji-card-element">
      🌈🐻
    </span>
    <span className="landing-container__emoji-card-element">
      🧠
    </span>
  </div>
); 

const cardStyles = [
  { 
    backgroundColor: "#FF82E2", 
    filter: "invert(1)", 
    borderTopLeftRadius: "1rem",
  },
  { 
    backgroundColor: "#FED715", 
    borderTopRightRadius: "1rem",
  },
  { 
    backgroundColor: "#0037B3", 
    borderBottomLeftRadius: "1rem",
  },
  { 
    backgroundColor: "#70BAFF", 
    filter: "invert(1)", 
    borderBottomRightRadius: "1rem",
  },
];

// markup
const IndexPage = () => {
  return (
      <>
    <nav className="nav">
      <div className="nav__social-links">
        <a href="https://fb.me/kokitofb" className="nav__social-links-fb"  alt="facebook logo"  >
          <span></span>
        </a>
        <a href="https://www.instagram.com/kokitograms/" className="nav__social-links-insta">
        <span></span> 
        </a>
      </div>
    </nav>
    <div className="App">
    <main className="landing-container">
        <section className="landing-container__parallax landing-container__kokitowall" />
      <section className="landing-container__nft sm-mb-1">
        <div    
          className="landing-container__nft--square-grid">
          {
           cardStyles.map((style)=> 
              <EmojiCard key={style.color} style={style}/>
            )
          }
        </div>
        {/* <OpenSeaLink /> */}
      </section> 

        <section className="landing-container__logo sm-mb-1">
          <LogoChartOverlay />
        </section>
        <section className="landing-container__appStore sm-mb-1">
          <a href="https://apps.apple.com/us/app/kokito/id1553385495">
            <AppStoreDownload />
          </a>
        </section>
        <section className="landing-container__welcome">
            <h1>
              Kokito
            </h1>
            <h2>
              not your grandpa's financial analytics platform
            </h2>
        </section>
        <section className="landing-container__screen-one" />
        <section className="landing-container__screen-one-copy">
              <p> 
                Be part of Wall Street's elite, <em>WallStreetBets</em> a community of Wall Street veterans, quant traders, and enthusiasts.
              </p>
              <h2>#apes</h2>
        </section> 
        <section className="landing-container__screenTwo" />
        <section className="landing-container__demo" />
        <section
          className="landing-container__bio"> 
            <p>
              Helping pronouns make sense of <b><em>WallStreetBets</em></b>. We believe that all retail traders should have access to valuable data, not just the <b>suits</b>.
            </p>
        </section>
        
      </main>
    </div>
    </>
  )
}

export default IndexPage
