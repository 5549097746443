import React, { useLayoutEffect, useState } from 'react'
import kokitoLogo from './Kokito.png'
export const KOKITO_LOGO_ID = 'kokito-logo';

export const Logo = () => {
    return (
    <img 
    id={KOKITO_LOGO_ID}
    src={kokitoLogo} alt="kokito logo, a french bull dog in a tuxedo" />        
    )
}

export const useSizeById = (ID = KOKITO_LOGO_ID) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    
    const getSize = () => {
        const logoWidth = document.getElementById(ID).clientWidth
        const logoHeight = document.getElementById(ID).clientHeight
        setWidth(logoWidth)
        setHeight(logoHeight)
    };
    useLayoutEffect(() => {
        const observer = new ResizeObserver(getSize).observe(document.getElementById(ID));
        return () => {
           observer.disconnect(); 
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        height,
        width,
    }
}
