import React, { useState, useLayoutEffect } from 'react';
import { LineChart, Line, ResponsiveContainer } from 'recharts';

const data = [
  {
    price: 5,
  },
  {
    price: 15,
  },
  {
    price: 15,
  },
  {
    price: 1,
  },
  {
    price: 10,
  },
  {
    price: 15,
  },
  {
    price: 1,
  },
  {
    price: 10,
  }, 
  {
    price: 5,
  },
  {
    price: 5,
  },
  {
    price: 15,
  },
  {
    price: 15,
  },
  {
    price: 1,
  },
  {
    price: 10,
  },
  {
    price: 15,
  },
  {
    price: 1,
  },
  {
    price: 10,
  }, 
  {
    price: 5,
  },
  {
    price: 5,
  },
  {
    price: 5,
  },
  {
    price: 5,
  },
  {
    price: 1,
  },
  {
    price: 5,
  },
  {
    price: 5,
  },
  {
    price: 5,
  },
  {
    price: 10,
  }, 
  {
    price: 5,
  },
  {
    price: 4,
  },
  {
    price: 1,
  },
  {
    price: 5,
  },
  {
    price: 15,
  },
  {
    price: 15,
  },
  {
    price: 3,
  },
  {
    price: 3,
  },
  {
    price: 5,
  },
  {
    price: 50,
  },
  {
    price: 3,
  },
  {
    price: 4,
  },
  {
    price: 45,
  },
  {
    price: 5
  },
  {
    price: 150
  }
];

const LineChartWrapper = ({
  height,
  width,
  hover = false
}) => {
  const [render, setRender] = useState(false);
  useLayoutEffect(() => {
    if (hover) setRender(false)
    setTimeout(() => {
      setRender(true);
   }, hover ? 0 : 1000);
  }, [hover])
  if (render) {
    return (
      <ResponsiveContainer 
      height={height}
      width={width}
      >
        <LineChart data={data}>
          <Line type="monotone" dataKey="price" stroke="#5BAE57" dot={false} strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    );
  }

  return null
  
};

export default LineChartWrapper;
