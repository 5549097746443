import React, { useState } from 'react'
import LineChart from '../LineChart'
import { Logo, useSizeById, KOKITO_LOGO_ID} from '../Logo'
import './styles.scss'
export const LogoChartOverlay = () => {
    const [hover, setHovered] = useState(false)
    const size = useSizeById(KOKITO_LOGO_ID)
    return (
        <div onMouseEnter={() => setHovered(true)} onMouseOut={() => setHovered(false)} className="logo-chart-overlay">
            <LineChart {...size} hover={hover} />
            <Logo />
        </div>
    )
}
