import React from 'react'
import AppDownloadImg from './appStoreDownload.svg'
export const AppStoreDownload = () => {
    return <img src={AppDownloadImg} 
                alt="ios app store download for Kokito"
                style={{ 
                    width: "100%", 
                    height: "100%"
                 }}
        />
}
